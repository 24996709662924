<template>
   <div>
      <header>
         <nav class="bg-indigo-900">
            <div class="container px-6 py-3 mx-auto md:flex md:justify-between md:items-center">
                  <div class="flex items-center justify-between">
                     <div>
                        <router-link to="/" class="text-2xl font-bold text-white transition-colors duration-200 transform hover:text-gray-100">Escrowlock</router-link>
                     </div>
                     <!-- Mobile menu button -->
                     <div class="flex md:hidden">
                        <button type="button" class="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400" aria-label="toggle menu">
                              <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                                 <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                              </svg>
                        </button>
                     </div>
                  </div>

                  <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
                  <div class="items-center md:flex">
                     <div class="md:flex flex-col hidden md:flex-row md:mx-6">
                        <router-link to="/help" class="text-sm font-medium text-white transition-colors duration-200 transform hover:text-gray-100 md:mx-4 md:my-0">Help and FAQs</router-link>   
                     </div>
                  </div>
            </div>
         </nav>
      </header>
      <div class="grid grid-cols-1 place-content-center max-w-xl mx-auto py-12">
         <div class="text-3xl font-medium text-indigo-900  text-center">
            Complete account registration
         </div>

         <div class="text-xl flex justify-center space-x-3 text-center font-medium my-5 text-indigo-900">
            <div>Step {{ step }} <span class="text-gray-600 font-normal">/ 2</span> </div>
            <div class="flex space-x-1 self-center">
               <div class="border h-2.5 w-2.5 rounded-full transition" :class="step == 1 || step == 2 ? 'bg-indigo-900 border-indigo-900' : 'bg-white border-gray-300'"></div>
               <div class="border h-2.5 w-2.5 rounded-full transition" :class="step == 2 ? 'bg-indigo-900 border-indigo-900' : 'bg-white border-gray-300'"></div>
            </div>
         </div>

         <div>
            <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="">
               <form class="flex flex-col justify-center" @submit.prevent="handleSubmit(nextStep)">
                  <div class="grid grid-cols-2 gap-5 bg-white rounded-md shadow-xl p-10 animate__animated animate__fadeIn" v-if="step == 1 ">
                     <!--div class="text-xl text-indigo-900 col-span-2 font-semibold">Your Profile</div-->
                     <ValidationProvider tag="div" name="country" class="col-span-2 relative" rules="required" v-slot="{ errors }">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Country</label>
                        <og-custom-select :data="countries" :showImage="false" v-model="form.country" v-on:valueSelect="onCountrySelect"></og-custom-select>
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="state" class="col-span-2 relative" rules="required" v-slot="{ errors }" v-if="form.country.value != ''">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">State</label>
                        <og-custom-select :data="states" :showImage="false" v-model="form.state" v-on:valueSelect="onStateSelect"></og-custom-select>
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="city" rules="required" class="col-span-2 relative" v-slot="{ errors }" v-if="form.country.value != '' && form.state.value != ''">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">City</label>
                        <input type="text" v-model="form.city"
                           class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                           spellcheck="false"/>
                        <!--og-custom-select :data="cities" :showImage="false" v-model="form.city" v-on:valueSelect="onCitySelect"></og-custom-select-->
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="street name" rules="required" class="col-span-2" v-slot="{ errors }">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Street name &amp; number</label>
                        <textarea type="text" v-model="form.address" rows="5"
                           class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                           spellcheck="false"></textarea>
                           <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <hr class="border-t border-gray-200 my-3 col-span-2" />
                     <template v-if="showBusiness">
                        <div class="grid grid-cols-2 gap-5 bg-white col-span-2 animate__animated animate__fadeIn">
                           <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Business Profile</div>
                           <ValidationProvider tag="div" name="business name" class="col-span-2 relative" rules="" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business Name</label>
                              <input type="text" v-model="form.business_name"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"/>
                              <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" class="col-span-2" name="business email" :rules="form.business_name != '' ? 'required|email' : 'email'" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business Email</label>
                              <input type="email" v-model="form.business_email"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"/>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="business phone number" rules="" class="col-span-2" v-slot="{ errors }">
                              <label class="text-gray-700 font-semibold">Business Phone Number</label>
                              <MazPhoneNumberInput v-model="form.business_phone" default-country-code="NG" class="mt-2" :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"/>
                              <span class="text-sm  text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <ValidationProvider tag="div" name="business location" rules="" class="col-span-2" v-slot="{ errors }">
                              <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business location (Street name &amp; number)</label>
                              <textarea type="text" v-model="form.business_address" rows="5"
                                 class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading"
                                 :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                                 spellcheck="false"></textarea>
                                 <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                           </ValidationProvider>
                           <div class="bg-gray-100 flex rounded-md text-xs space-x-2 text-gray-500 p-3 col-span-2">
                              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-indigo-900" viewBox="0 0 20 20" fill="currentColor">
                                 <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                              </svg> <span class="self-center">Add a business account with a valid and verified Bank information.</span>
                           </div>
                        </div>
                     </template>
                     <div class="col-span-2 flex justify-between space-x-3">
                        <div class="self-center text-indigo-900 font-medium hover:underline text-sm cursor-pointer" v-if="!showBusiness" @click="showBusiness = true">
                           Add Business Account
                        </div>
                        <div class="self-center text-indigo-900 font-medium hover:underline text-sm cursor-pointer" v-else @click="showBusiness = false; form.business_name = ''; form.business_email = ''; form.business_phone = ''; form.business_address = '';">
                           Remove Business Account 
                        </div>
                        <div class="flex justify-end space-x-3">
                           <button type="submit" @click.prevent="logout()" class="leading-4 inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading px-5 py-3 md:px-6 bg-gray-300 
                              hover:bg-gray-400"> Cancel
                           </button>
                           <button type="submit" 
                           :class="form.country.value == '' || form.state.value == '' || form.city == '' || form.address == '' ? 'bg-indigo-300 hover:bg-indigo-400 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-900'"
                           @click.prevent="form.country.value != '' && form.state.value != '' && form.city != '' && form.address != '' ? handleSubmit(nextStep) : null" class="leading-4 inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6">
                                 Next Step
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
            <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="">
               <form class="flex flex-col justify-center" @submit.prevent="handleSubmit(nextStep)">
                  <div class="grid grid-cols-2 gap-5 bg-white rounded-md border p-10 animate__animated animate__fadeIn"  v-if="step == 2">
                     <div class="text-xl text-indigo-900 col-span-2 font-semibold">Personal Bank Information
                        <div class="col-span-2 text-gray-500 text-sm my-2 font-normal">Provide where you wish to recieve payments</div>
                     </div>
                     <ValidationProvider tag="div" name="bank" class="col-span-2 relative" rules="required" v-slot="{ errors }">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Select Bank</label>
                        <og-custom-select :data="banks" :showImage="false" v-model="form.bank" v-on:valueSelect="onBankSelect"></og-custom-select>
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="account number" class="col-span-2 relative" rules="required|numeric|min:6" v-slot="{ errors }">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Account Number</label><!--maxlength="10" oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"-->
                        <input type="text" v-model="form.account_number" :disabled="form.bank.value == ''"
                           class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                           spellcheck="false"/>
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <ValidationProvider tag="div" name="account name" class="col-span-2 relative" rules="required" v-slot="{ errors }" v-if="form.account_name != ''">
                        <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Account Name</label><!--maxlength="10" oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"-->
                        <input type="text" v-model="form.account_name" readonly disabled
                           class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                           :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                           spellcheck="false"/>
                        <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                     </ValidationProvider>
                     <div class="col-span-2">
                        <button type="button" 
                        :class="form.account_name.value == '' || form.account_number == '' ? 'bg-green-300 hover:bg-green-400 whitespace-nowrap border border-green-400 cursor-not-allowed' : 'bg-green-700 border border-green-800 hover:bg-green-600'"
                        @click.prevent="form.account_name.value != '' && form.account_number != '' ? validateAccountNumber() : null" class="leading-4 inline-flex items-center cursor-pointer transition 
                           ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                           focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-2 md:px-6">
                           <svg v-if="isSearching" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                           </svg> Get Account Name
                        </button>
                     </div>
                     <hr class="border-t border-gray-200 my-3 col-span-2" />
                     <template v-if="showBusinessBank">
                        <div class="text-xl pb-4 text-indigo-900 col-span-2 font-semibold">Business Bank Information</div>
                        <ValidationProvider tag="div" name="bank" class="col-span-2 relative" rules="required" v-slot="{ errors }">
                           <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Select Bank</label>
                           <og-custom-select :data="banks" :showImage="false" v-model="form.business_bank" v-on:valueSelect="onBusinessBankSelect"></og-custom-select>
                           <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider tag="div" name="business account number" class="col-span-2 relative" rules="required|numeric|min:6" v-slot="{ errors }">
                           <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business Account Number</label><!--maxlength="10" oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"-->
                           <input type="text" v-model="form.business_bank_account_number" :disabled="form.business_bank.value == ''"
                              class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                              :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              spellcheck="false"/>
                           <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <ValidationProvider tag="div" name="business account name" class="col-span-2 relative" rules="required" v-slot="{ errors }" v-if="form.business_bank_account_number != ''">
                           <label class="block text-gray-600 font-semibold leading-none mb-3 cursor-pointer">Business Account Name</label><!--maxlength="10" oninput="if(this.value.length > 10) return this.value = this.value.slice(0, 10)"-->
                           <input type="text" v-model="form.business_bank_account_name" readonly disabled
                              class="py-2 px-4 form-input md:px-5 w-full appearance-none transition duration-150 ease-in-out border text-input font-body rounded-md placeholder-body min-h-12 transition duration-200 ease-in-out bg-white border-gray-300 focus:outline-none focus:border-heading h-11 md:h-12"
                              :class="errors.length > 0 ? 'border-red-600 focus:border-red-600' : 'focus:border-indigo-600 '"
                              spellcheck="false"/>
                           <span class="text-sm text-red-600 font-semibold">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <div class="col-span-2">
                           <button type="button" 
                           :class="form.business_bank_name.value == '' || form.business_bank_account_number == '' ? 'bg-green-300 hover:bg-green-400 whitespace-nowrap border border-green-400 cursor-not-allowed' : 'bg-green-700 border border-green-800 hover:bg-green-600'"
                           @click.prevent="form.business_bank_name.value != '' && form.business_bank_account_number != '' ? validateAccountNumber() : null" class="leading-4 inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-2 md:px-6">
                              <svg v-if="isSearchingBusiness" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg> Get Account Name
                           </button>
                        </div>
                     </template>
                     <div class="bg-gray-100 flex rounded-md text-xs space-x-2 text-gray-500 p-3 col-span-2">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-indigo-900" viewBox="0 0 20 20" fill="currentColor">
                           <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg> 
                        <span>
                           If your Account name doesn't match the name provided during registration, your bank Account Name will be used on 
                           your profile instead.
                        </span>                     
                     </div>
                     <div class="col-span-2 flex justify-between space-x-3">
                        <div class="self-center text-indigo-900 font-medium hover:underline text-sm cursor-pointer" v-if="!showBusinessBank" @click="showBusinessBank = true">
                           Add Business Bank Account
                        </div>
                        <div class="self-center text-indigo-900 font-medium hover:underline text-sm cursor-pointer" v-else @click="showBusinessBank = false; form.business_bank = { id: 1, name: 'Select bank', value: '' }; form.business_bank_account_number = ''; form.business_bank_account_name = '';">
                           Hide Business Bank Account 
                        </div>
                        <div class="flex justify-end space-x-3">
                           <button type="submit" @click.prevent="previousStep()" class="leading-4 inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading px-5 py-3 md:px-6 bg-gray-300 
                              hover:bg-gray-400"> Back
                           </button>
                           <button type="submit" 
                           :class="form.account_name == '' && form.business_bank_account_name == '' ? 'bg-indigo-300 hover:bg-indigo-400 cursor-not-allowed' : 'bg-indigo-900 hover:bg-indigo-900'"
                           @click.prevent="form.account_name != '' || form.business_bank_account_name != '' ? handleSubmit(setupProfile) : null" class="leading-4 inline-flex items-center cursor-pointer transition 
                              ease-in-out duration-300 font-medium text-center justify-center border-0 border-transparent rounded
                              focus-visible:outline-none focus:outline-none focus:bg-opacity-80 bg-heading text-white px-5 py-3 md:px-6">
                              <svg v-if="isSubmitProfile" class="animate-spin -ml-1 mr-3 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                 <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                 <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                              </svg> Finish Up
                           </button>
                        </div>
                     </div>
                  </div>
               </form>
            </ValidationObserver>
         </div>
      </div>
      <footer class="inset-x-0 bottom-0 w-full bg-white border-heading p-5 mt-5 text-gray-500 font-medium 2xl:p-5">
         <div class="mx-4">
            <div class="flex flex-col-reverse md:flex-row text-center md:justify-center mx-auto max-w-full px-4 md:px-8 2xl:px-16">
               <p class="text-xs lg:text-sm leading-6">
               Copyright &copy; {{ new Date().getFullYear() }} <a class="font-bold text-indigo-900 transition-colors duration-200 ease-in-out hover:text-body" href="https://escrowlock.com">Escrowlock.</a> All rights reserved
               </p>
            </div>
         </div>
      </footer>
   </div>
</template>

<script>
import { mapGetters } from 'vuex'
import scrollToTop from '@/mixins/scrollToTop.js'
import { mapActions } from 'vuex'
import OgCustomSelect from '@/components/og-custom-select.vue'
export default {
   mixins: [scrollToTop],
   data() {
      return {
         step: 1,
         countries: [],
         states: [],
         cities: [],
         isLoading: false,
         form: {
            country: { id: 1, name: "Select country", value: '' },
            state: { id: 1, name: "Select state", value: '' },
            bank: { id: 1, name: "Select bank", value: '' },
            business_bank: { id: 1, name: "Select bank", value: '' },
            account_name: '',
            account_number: '',
            city: '',
            first_name: '',
            last_name: '',
            middle_name: '',
            address: '',
            business_name: '',
            business_email: '',
            business_phone: '',
            business_address: '',
            business_bank_name: '',
            business_bank_account_number: '',
            business_bank_account_name: '',
         },
         showBusinessBank: false,
         banks: [],
         selectedBankCode: null,
         selectedBusinessBankCode: null,
         isSearching: false,
         isSearchingBusiness: false,
         isSubmitProfile: false,
         is_name_match_bank: false,
         showBusiness: false
      }
   },

   components: {
      OgCustomSelect
   },

   watch: {
     /* account_number: function () {
         if (this.form.bank && this.form.account_number.length == 10) {
            this.validateAccountNumber()
         }
      },*/
   },

   methods: {
      ...mapActions('auth', ['get_countries', 'get_states', 'get_cities', 'setup_profile', 'getUserData']),

      ...mapActions('transactions', ['get_banks', 'validate_account_number']),

      nextStep() {
         this.step++
         this.scrollToTop()
         // get current
         /*let getTransData = this.getTransactionData()
         getTransData.step = this.step
         getTransData.formData = this.form
         window.localStorage.setItem('transaction-role', JSON.stringify(getTransData))*/

         return
      },

      previousStep() {
         this.step--
         this.scrollToTop()
         // get current
         /*let getTransData = this.getTransactionData()
         getTransData.step = this.step
         getTransData.formData = this.form
         window.localStorage.setItem('transaction-role', JSON.stringify(getTransData))*/

         return
      },

      async getCountries() {
         try {
            let res = await this.get_countries()
            this.countries = res
         } catch(error) {
            this.countries = []
         }
      },

      onCountrySelect(value) {
         if(this.form.country == '') {
            this.form.country = this.user.account.country
         }

         this.form.country = value
         this.getStates(this.form.country.iso2)
         this.getBanks()
      },

      onBusinessCountrySelect(value) {
         if(this.form.business_country == '') {
            this.form.business_country = this.user.account.country
         }

         this.form.country = value
         this.getStates(this.form.country.name)
      },

      async getStates(iso) {
         const endpoint_params = {
            ciso: iso
         }

         try{
            let res = await this.get_states(endpoint_params)
            this.states = res
         } catch(error) {
            this.states = []
         }
      },

      onStateSelect(value) {
         if(this.form.state == '') {
            this.form.state = this.user.account.state
         }

         this.form.state = value

         //this.getCities(this.form.country.name, this.form.state.name)
      },

      async getCities(country, state) {
         const endpoint_params = {
            country: country,
            state: state
         }
         try {
            let res = await this.get_cities(endpoint_params)
            this.cities = res.data
         } catch(error) {
            this.cities = []
         }
      },

      onCitySelect(value) {
         if(this.form.city == '') {
            this.form.city = this.user.account.state
         }

         this.form.city = value
      },

      async getBanks() {
         const endpoint_params = {
            country: this.form.country.name.toLowerCase()
         }
         try{
            let res = await this.get_banks(endpoint_params)
            this.banks = res.data
         } catch(error) {
            this.banks = []
         }
      },

      onBankSelect(value) {
         this.form.bank = value
         this.selectedBankCode = value.code

         if(this.form.account_number != '') {
            this.validateAccountNumber()
         }
      },

      onBusinessBankSelect(value) {
        this.form.business_bank = value
         this.selectedBusinessBankCode = value.code

         if(this.form.business_bank_account_number != '') {
            this.validateAccountNumber()
         }
      },

      async validateAccountNumber() {
         // Profile 
         if(this.form.account_number.length >= 6) {
            this.isSearching = true
            const endpoint_params = {
               account_number: this.form.account_number,
               bank_code: this.selectedBankCode
            }
            try{
               let res = await this.validate_account_number(endpoint_params)
               if(res.status == true) {
                  this.form.account_name = res.data.account_name
                  this.check_user_name_vs_bank_name()
               } else {
                  this.$toast.error('Account information not found, please try again')
                  this.form.account_number = ''
                  this.isLoading = false
               }
               this.isSearching = false
            } catch(error) {
               this.isSearching = false
            }
         }

         // Business
         if (this.form.business_bank_account_number.length >= 6) {
            this.isSearchingBusiness = true
            const endpoint_params = {
               account_number: this.form.business_bank_account_number,
               bank_code: this.selectedBusinessBankCode
            }

            try{
               let res = await this.validate_account_number(endpoint_params)
               if(res.status == true) {
                  this.form.business_bank_account_name = res.data.account_name
                  this.check_user_name_vs_bank_name()
               } else {
                  this.$toast.error('Account information not found, please try again')
                  this.form.business_bank_account_name = ''
                  this.isSearchingBusiness = false
               }
               this.isSearching = false
            } catch(error) {
               this.isSearchingBusiness = false
            }
         }
      },

      async setupProfile() {
         this.isSubmitProfile = true
         //this.form.username = this.user.username
         this.form.last_name = this.form.last_name == '' ? this.user.account.last_name : this.form.last_name
         this.form.first_name = this.form.first_name == '' ? this.user.account.first_name : this.form.first_name
         this.form.middle_name = this.form.middle_name == '' ? this.user.account.middle_name : this.form.middle_name
         this.form.user_country = this.form.country.name
         this.form.user_state = this.form.state.name
         this.form.user_bank = this.form.bank.name

         try {
            let res = await this.setup_profile(this.form)
            if(res.status == 'success') {
               this.getUserData()
               this.$toast.success(res.message)
               setTimeout(() => {
                  this.$router.push({ name: 'UserLoading' })
               }, 1000)
            }
            this.isSubmitProfile = false
         } catch (error) {
            this.isSubmitProfile = false
            this.$toast.error(error.response.data.message)
         }
      },

      async logout() {
         try {
            let res = await this.$store.dispatch('auth/token_terminate')
            
            this.$toast.success(res.message)
         } catch (error) {
            this.$toast.error(error.response.message)
            if(error.response && error.response.status === 401) {
               this.$store.commit('logout');
               this.router.push('/auth/sign-in');
            }
         }
      },

      check_user_name_vs_bank_name() {
         
         let account_name = this.form.account_name
         account_name = account_name.split(" ")
         
         /*
         var check_score = 0
         
         if(account_name.includes(this.user.account.first_name.toUpperCase()) == true) {
            check_score = check_score + 1
         }
         if(account_name.includes(this.user.account.middle_name.toUpperCase()) == true) {
            check_score = check_score + 1
         }
         
         if(account_name.includes(this.user.account.last_name.toUpperCase()) == true) {
            check_score = check_score + 1
         }

         if(this.form.business_bank_account_name != '') {
            let business_account_name = this.form.business_account_name 
            //business_account_name = business_account_name.split(" ")

            if(business_account_name.includes(this.form.business_name.toUpperCase()) == true) {
               check_score = check_score + 1
            }
         }
      
         if(check_score >= 2) {
            this.is_name_match_bank = true
         } else {
            /*setTimeout(() => {
               this.form.account_number = ''
               this.form.account_name = ''
            }, 2000)
            this.$toast.error("Your provided name must match bank account name!")
            /
           this.form.last_name = account_name[0]
           this.form.first_name = account_name[1]
           this.form.middle_name = account_name[2]
         } */

         //console.log(this.$options.capitalize(account_name[0]))
         this.form.last_name = account_name[0].toLowerCase()
         this.form.first_name = account_name[1].toLowerCase()
         this.form.middle_name = account_name[2].toLowerCase()
      }
   },

   computed: {
      ...mapGetters('auth', ['user'])
   },

   mounted() {
      this.getCountries()
   }
}
</script>